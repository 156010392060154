<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import getUA from '@utils/getUA'

const route = useRoute()

onMounted(() => {
  init()
})

const init = () => {
  let queryParams = ''
  if (getUA().isIOS && route.query.ct) {
    queryParams = `ct=${route.query.ct}&pt=126093046&mt=8`
  }
  if (getUA().isAndroid && route.query.referrer) {
    queryParams = `referrer=${route.query.referrer}`
  }

  switch (true) {
    case getUA().isIOS:
      window.location.href = `https://apps.apple.com/app/apple-store/id6446071834?${queryParams}`
      break
    case getUA().isAndroid:
      window.location.href = `https://play.google.com/store/apps/details?id=com.oceangalaxy.camera.new&${queryParams}`
      break
    default:
      window.location.href = 'https://timemark.com/'
      break
  }
}
</script>

<template>
  <div></div>
</template>

<style scoped lang="scss"></style>
